[class^='formularioReserva_One'] .error {
    border: 2px solid red;
}

.react-confirm-alert-overlay {
    z-index: 1200;
    background-color: rgba(77, 77, 77, 0.911);
}

.react-confirm-alert-overlay .react-confirm-alert-body {
    /* border: 1px solid gainsboro; */
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
    font-size: 18px;
}

.react-confirm-alert-overlay .react-confirm-alert-body h1 {
    margin-bottom: 15px;
    font-size: 25px;
}

.overlay-confirm-suprimir .react-confirm-alert-body .confirm-btn {
    background-color: #38ba7c;
}

.overlay-confirm-suprimir .react-confirm-alert-body .cancel-btn {
    background-color: #8c8c8c;
}

.overlay-confirm-suprimir .react-confirm-alert-body .close-btn {
    background-color: #c36069;
}

.modal .closeBtn i {
    font-size: 20px;
}

.modal .closeBtn i:hover {
    cursor: pointer;
}

.modal .btn-cancel {
    background-color: #c36069;
    color: #FFF !important;
}

.modal .btn-cancel:hover, .modal .btn-cancel:focus {
    background-color: #ae565e;
    border-color: #ae565e;
}

.modal.infoReserva .modal-header {
    padding-inline: 20px;
}

.modal.infoReserva .modal-header span:first-child {
    font-weight: bold;
    font-size: 20px;
}

.ant-modal-root .ant-modal-mask, .ant-modal-root .ant-modal-wrap {
    z-index: 500;
}

.ant-modal .ant-modal-close {
    position: absolute;
    right: 12px;
    left: unset;
    top: 10px;
}

.ant-modal .ant-modal-close svg {
    font-size: 20px;
}

.ant-select-selector .ant-select-selection-placeholder {
    color: #aaa !important;
}

.ant-select .anticon-loading svg {
    fill: var(--primary-color);
}

.primaryColor {
    color: var(--primary-color) !important;
}

.whiteColor {
    color: #FFF !important;
}

span.red {
    color: red !important;
}

/* BUTTONS */

button.ant-btn.basic-btn {
    background-color: #9a9a9a;
    border-color: #9a9a9a;
    line-height: 20px;
}

button.ant-btn.main-btn {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    line-height: 20px;
}

button.ant-btn.basic-btn.accept-btn {
    background-color: #78ad62;
    border-color: #78ad62;
}

button.ant-btn.main-btn span,
button.ant-btn.basic-btn span {
    color: #fff !important;
}

button.ant-btn.main-btn span svg,
button.ant-btn.basic-btn span svg {
    fill: #fff !important;
    font-size: 14px;
}

button.ant-btn.basic-btn:hover {
    background-color: #6a6a6a;
    border-color: #6a6a6a;
}

button.ant-btn.main-btn:hover {
    background-color: var(--primary-color-dark);
    border-color: var(--primary-color-dark);
}

button.ant-btn.basic-btn.accept-btn:hover {
    background-color: #588d42;
    border-color: #588d42;
}

button.ant-btn.basic-btn:disabled,
button.ant-btn.main-btn:disabled {
    opacity: .5;
}

button.ant-btn.main-btn.btn-medium span {
    font-size: 14px;
}

button.rounded-btn {
    height: 40px;
    border-radius: 40px !important;
    padding-inline: 12px;
}

button.rounded-btn p {
    color: #FFF;
}

button.rounded-btn span {
    font-size: 20px;
    line-height: 12px;
    margin-right: 5px;
}

button.ant-btn svg {
    display: block;
}

button.ant-btn.iconCircle {
    padding: 0;
    border: none;
    box-shadow: none;
    background-color: transparent;
}

button.ant-btn.iconCircle:disabled {
    opacity: .5;
}

button.ant-btn.iconCircle span {
    margin-top: 4px;
}

button.ant-btn.iconCircle svg {
    font-size: 24px;
}

button.ant-btn.main-btn.disabledNoClick, button.ant-btn.main-btn.disabledClick {
    opacity: .5;
}

button.ant-btn.main-btn.disabledNoClick {
    pointer-events: none;
}

button.ant-btn.iconCircle {
    background-color: #8c8c8c;
}

button.ant-btn.iconCircle.mainBtn {
    background-color: var(--primary-color);
}

button.ant-btn.iconCircle.editBtn {
    background-color: #5675ae;
}

button.ant-btn.iconCircle.deleteBtn {
    background-color: #ae565e;
}

button.ant-btn.iconCircle.confirmBtn {
    background-color: #8aba79;
}

button.ant-btn.iconCircle.historicBtn {
    background-color: #6ea59d;
}

button.ant-btn.iconCircle.lockBtn {
    background-color: #f5b798;
}

button.ant-btn.iconCircle.lockBtn svg {
    font-size: 18px !important;
}

.validateBtn svg {
    font-size: 18px !important;
}

/* ICONS */

span.anticon.greyColor svg {
    fill: #8c8c8c;
}

span.anticon.white svg {
    fill: #FFF;
}

span.anticon.black svg {
    fill: #000;
}

/* NOTIFICATIONS */

.ant-notification-notice-warning .anticon-exclamation-circle svg {
    fill: #faad14;
    font-size: 22px;
}

.ant-notification-notice-error .anticon-close-circle svg {
    fill: var(--primary-color);
    font-size: 22px;
}

/* TABLE */

.ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 8px 16px !important;
}

.ant-table-filter-dropdown .ant-table-filter-dropdown-btns {
    gap: 10px;
}

.ant-table-filter-dropdown .ant-table-filter-dropdown-btns .ant-btn.ant-btn-primary {
    background-color: var(--primary-color);
}

.ant-table-filter-dropdown .ant-table-filter-dropdown-btns .ant-btn.ant-btn-primary span {
    color: #FFF !important;
}

.ant-table-filter-dropdown .ant-checkbox-checked .ant-checkbox-inner,
.ant-table-filter-dropdown .ant-checkbox-checked:hover .ant-checkbox-inner {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-measure-row > td {
    padding: 1px 0 !important;
}

.ant-table-filter-column .ant-table-filter-trigger.active svg,
.ant-table-column-sorters .ant-table-column-sorter .ant-table-column-sorter-inner .anticon.active svg {
    fill: var(--primary-color);
}

/* TAGS */

.bigCalendar-container .ant-tag:before {
    content: "";
    padding-inline: 6px;
    font-size: 9px;
    border-radius: 50%;
    margin-right: 5px;
    opacity: .8;
}

.ant-tag.ant-tag-green {
    color: #389e0d !important;
}

.bigCalendar-container .ant-tag.ant-tag-green:before {
    background-color: #389e0d !important;
}

.ant-tag.ant-tag-orange {
    color: #d46b08 !important;
}

.bigCalendar-container .ant-tag.ant-tag-orange:before {
    background-color: #d46b08 !important;
}

.ant-tag.ant-tag-red {
    color: #cf1322 !important;
}

.bigCalendar-container .ant-tag.ant-tag-red:before {
    background-color: #cf1322 !important;
}

.ant-tag.ant-tag-blue {
    color: #0958d9 !important;
}

.bigCalendar-container .ant-tag.ant-tag-blue:before {
    background-color: #0958d9 !important;
}

.ant-tag.ant-tag-has-color.simpleTag {
    color: #FFF !important;
}

/* TABS */

.ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary-color);
    /* font-weight: bold; */
}

.ant-tabs-nav .ant-tabs-nav-list .ant-tabs-ink-bar {
    background: var(--primary-color);
}

/* TEXT */

span.ant-typography.ant-typography-warning {
    color: #faad14 !important;
}

/* BADGES */

.ant-badge .ant-badge-count {
    background: var(--primary-color);
}

.ant-badge .ant-badge-count span {
    color: #FFF !important;
}

/* LAYOUT */

.ant-layout .ant-layout-sider,
.ant-layout .ant-layout-header,
.ant-menu-dark {
    /* background: #dfdfdf; */
    background: #f0f0f0;
}

.ant-layout .ant-layout-sider-trigger {
    /* background: #b6b6b6; */
    background: #d8d8d8;
}

.ant-layout-header.collapsed {
    padding-left: 130px;
}

.ant-layout-header.uncollapsed {
    padding-left: 250px;
}

.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item {
    margin-inline: 0;
    width: 100%;
    border-radius: 0;
}

.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: var(--primary-color);
}

.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:active {
    background-color: var(--primary-color) !important;
}

.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item svg {
    font-size: 18px;
}

.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected svg,
.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:active svg {
    fill: #FFF;
}

.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected span,
.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:active span {
    color: #FFF !important;
}

.avatarHeader svg {
    font-size: 16px;
}

/* CHECKBOX */

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    border-color: var(--primary-color);
}

.ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    background-color: var(--primary-color);
}

.ant-checkbox-wrapper span {
    font-weight: 400;
}

/* TIMEPICKER */

.ant-picker-dropdown .ant-picker-ranges {
    justify-content: center;
}

.ant-picker-dropdown .ant-picker-ranges .ant-picker-ok button {
    background-color: var(--primary-color);
}

.ant-picker-dropdown .ant-picker-ranges .ant-picker-ok button span {
    color: #FFF !important;
}

.ant-picker-dropdown .ant-picker-ranges .ant-picker-ok {
    display: none;
}

/* DATAPICKER */

.ant-picker-dropdown .ant-picker-panel .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-panel .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: var(--primary-color);
}

.ant-picker-dropdown .ant-picker-panel .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-dropdown .ant-picker-panel .ant-picker-cell-in-view.ant-picker-cell-range-start::before,
.ant-picker-dropdown .ant-picker-panel .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
    background: var(--primary-color-opacity-15) !important;
}

/* MENU */

.ant-menu-dark.ant-menu-submenu > .ant-menu,
.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background-color: #f0f0f0;
}

.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    margin-inline: 0px;
    width: 100%;
    border-radius: 0px;
}

.ant-menu-submenu .anticon > svg {
    font-size: 17px;
}

.ant-menu-submenu-selected .ant-menu-submenu-title,
.ant-menu-submenu-selected .ant-menu-submenu-title:hover {
    background-color: var(--primary-color) !important;
}

.ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-title-content {
    color: #FFF;
}

.ant-menu-submenu-selected .ant-menu-submenu-title svg {
    fill: #FFF;
}

.ant-menu-submenu-selected.ant-menu-submenu-open .ant-menu-submenu-title {
    background-color: transparent !important;
}

.ant-menu-submenu-selected.ant-menu-submenu-open .ant-menu-submenu-title .ant-menu-title-content {
    color: #000;
}

.ant-menu-submenu-selected.ant-menu-submenu-open .ant-menu-submenu-title svg {
    fill: #000;
}

.ant-menu-submenu-selected.ant-menu-submenu-open .ant-menu-submenu-title .ant-menu-submenu-arrow {
    color: #000;
}

/* B2B */

.swith-mode-btn {
    width: 50px;
    margin: auto;
}

.swith-mode-btn.ant-switch-checked,
.swith-mode-btn.ant-switch-checked:focus,
.swith-mode-btn.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background-color: #b7b76c;
}

.swith-mode-btn,
.swith-mode-btn:focus,
.swith-mode-btn:hover:not(.ant-switch-disabled) {
    background-color: #565a5e;
    border-radius: 100px !important;
}

.ant-switch.swith-mode-btn .ant-switch-inner > span {
    font-size: 6px;
}

.ant-switch.swith-mode-btn .ant-switch-inner .ant-switch-inner-checked {
    margin-inline-start: 3px;
    margin-inline-end: 30px;
}

.ant-switch.swith-mode-btn .ant-switch-inner .ant-switch-inner-unchecked {
    margin-top: -22px;
    margin-inline-start: 30px;
    margin-inline-end: 3px;
}

/* EMPTY */

.emptyBoxTable .ant-empty-description span {
    color: #a0a0a0 !important;
}

/* ANIMATIONS */

.bounce {
	/* -webkit-animation: bounce 2s; */
	animation: bounce 2s;
	/* -webkit-animation-iteration-count: infinite; */
	animation-iteration-count: infinite;
    /* -webkit-animation: shake 0.8s cubic-bezier(.36,.07,.19,.97) infinite; */
    /* animation: shake 0.8s cubic-bezier(.36,.07,.19,.97) infinite;
    animation-delay: 20s; */
}

.blob.orange {
    background: rgba(140, 140, 140, 1) !important;
    box-shadow: 0 0 0 0 rgba(140, 140, 140, 1);
    animation: pulse-orange 2s infinite;
}

.ring {
    animation: ring 4s .7s ease-in-out infinite;
    transform-origin: 50% 4px;
}

@-webkit-keyframes bounce {
	0%,
	25%,
	50%,
	75%,
	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
	40% {
		-webkit-transform: translateY(-8px);
		        transform: translateY(-8px);
	}
	60% {
		-webkit-transform: translateY(-5px);
		        transform: translateY(-5px);
	}
}

@keyframes bounce {
	0%,
	25%,
	50%,
	75%,
	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
	40% {
		-webkit-transform: translateY(-8px);
		        transform: translateY(-8px);
	}
	60% {
		-webkit-transform: translateY(-5px);
		        transform: translateY(-5px);
	}
}

@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
}


@keyframes pulse-orange {
    0% {
      /* transform: scale(0.95); */
      box-shadow: 0 0 0 0 rgba(140, 140, 140, 0.7);
    }
    
    70% {
      /* transform: scale(1); */
      box-shadow: 0 0 0 10px rgba(140, 140, 140, 0);
    }
    
    100% {
      /* transform: scale(0.95); */
      box-shadow: 0 0 0 0 rgba(140, 140, 140, 0);
    }
}

@keyframes ring {
    0% { transform: rotate(0); }
    1% { transform: rotate(30deg); }
    3% { transform: rotate(-28deg); }
    5% { transform: rotate(34deg); }
    7% { transform: rotate(-32deg); }
    9% { transform: rotate(30deg); }
    11% { transform: rotate(-28deg); }
    13% { transform: rotate(26deg); }
    15% { transform: rotate(-24deg); }
    17% { transform: rotate(22deg); }
    19% { transform: rotate(-20deg); }
    21% { transform: rotate(18deg); }
    23% { transform: rotate(-16deg); }
    25% { transform: rotate(14deg); }
    27% { transform: rotate(-12deg); }
    29% { transform: rotate(10deg); }
    31% { transform: rotate(-8deg); }
    33% { transform: rotate(6deg); }
    35% { transform: rotate(-4deg); }
    37% { transform: rotate(2deg); }
    39% { transform: rotate(-1deg); }
    41% { transform: rotate(1deg); }
    43% { transform: rotate(0); }
    100% { transform: rotate(0); }
  }