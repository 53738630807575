.finishActivity {
    background-color: #ececec;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.finishActivity .resourcesActivity span {
    background-color: #e0f3f9;
    padding: 5px 10px;
    border-radius: 8px;
    margin-top: 8px;
    display: inline-block;
    margin-left: 8px;
    border: 1px solid lightblue;
}

.finishActivity .resourcesActivity .ant-tag.ant-tag-has-color.simpleTag {
    background-color: #d3e5d3 !important;
    border: 1px solid lightgreen !important;
    color: #000 !important;
    height: 30px;
    line-height: 18px;
} 

.ratesActivity {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.ratesActivity > div {
    flex: 0 1 calc(50% - 10px);
}

.ratesActivity > div > div {
    background-color: aliceblue;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #d2d2d2;
}

.finishActivity .actionBtns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    gap: 10px;
}

.finishActivity .actionBtns .iconCircle {
    background-color: #8c8c8c;
    border-radius: 50% !important;
    padding-inline: 6px;
    height: 30px;
}

.finishActivity .actionBtns .iconCircle.editBtn {
    background-color: #5675ae;
}

.finishActivity .actionBtns .iconCircle.deleteBtn {
    background-color: #ae565e;
}

.finishActivity .actionBtns .iconCircle svg {
    font-size: 18px;
}